@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}
/* 
body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
} */

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}



input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  
  color: black; /* White text color */
  -webkit-text-fill-color: black; /* White text color for WebKit browsers */
  -webkit-box-shadow: 0 0 0px 1000px #f5f5f5 transparent; /* Box shadow for WebKit browsers */
  box-shadow: 0 0 0px 1000px transparent inset; /* Box shadow */
  background-color: transparent; /* Transparent background */
  transition: background-color 5000s ease-in-out 0s; /* Transition */
  

  color: white;
}
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}




::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #b4beb0; 
  opacity: 0.4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b4beb0; 
  opacity: 0.4;
}


